body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.helper-container {
  display: grid;
  grid-template-areas:
    "guesses words"
  ;
  grid-template-columns: 50vw 50vw;
  grid-template-rows: 100vh;
}

.helper-container > * {
  padding: 5px;
}

.helper-hint {
  color: darkgrey;
  text-align: center;
  max-width: 50%;
  margin: auto;
  padding: 15px;
}

@media screen and (max-width: 600px) {
  .helper-container {
    grid-template-areas:
      "guesses"
      "words"
  ;
    grid-template-columns: 100vw;
    grid-template-rows: 50vh 50vh;
  }
}

.guesses-container {
  grid-area: guesses;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: scroll;
  border-right: 1px solid grey;
  border-bottom: none;
}

@media screen and (max-width: 600px) {

  .guesses-container {
    border-right: none;
    border-bottom: 1px solid grey;
  }
}

.guesses-container .buttons {
  margin: 5px;
}

.guesses-container .guess-container {
  width: 90%;
  max-width: 500px;
  margin: 5px auto;
  display: flex;
  flex-direction: column;
}

.status-box {
  border: 2px solid darkgrey;
  cursor: pointer;
}

.status-box:hover {
  border: 2px solid deepskyblue;
}

.words-container {
  overflow: scroll;
}

.words-list {
  grid-area: words;
  display: flex;
  flex-wrap: wrap;
}

.words-count-container {
  z-index: -1;
  position: absolute;
}

.words-count {
  color: lightgrey;
  font-size: 96px;
  padding: 10px;
}

.word {
  padding: 2px 4px;
  margin: 10px auto;
  display: inline;
  font-family: monospace;
  font-size: 16px;
}

.word:hover {
  background-color: lightgreen;
  cursor: pointer;
}
